import { REGION, REGIONS, US_BASE_API, BASE_API } from 'constant';
import { SERVICE_OPTIONS_LABELS } from 'constant/constants';
import { isEmpty } from 'lodash';
import { Outlet } from './types';

export const isClient = () => {
  return typeof window !== 'undefined';
};
export function isDesktop() {
  return !/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

/**
 * Calculates the haversine distance between point A, and B.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
export const haversineDistance = ([lat1, lon1]: number[], [lat2, lon2]: number[], isMiles = false) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

export const getIpAddress = (req: any) => {
  return (req.headers['x-forwarded-for'] || '')?.split(',').pop().trim() || req.socket.remoteAddress;
};

export const sortOutlets = (latLong: { latitude: number; longitude: number }, outlets?: Outlet[]) => {
  if (outlets && outlets.length > 0) {
    if (!isEmpty(latLong)) {
      const outletsData = outlets.map((outlet) => {
        return {
          ...outlet,
          distance: !isEmpty(outlet.latLong)
            ? haversineDistance([latLong.latitude, latLong.longitude], outlet.latLong, REGION === REGIONS.US)
            : null,
        };
      });
      outletsData.sort((a, b) => {
        if (a.distance === undefined || a.distance === null) {
          return 1;
        }
        if (b.distance === undefined || b.distance === null) {
          return -1;
        }
        return a.distance - b.distance;
      });
      return outletsData;
    }
    return outlets;
  }
  return [];
};

export const getServiceOptionsLabels = (serviceOptions: string[] = []) =>
  serviceOptions.map((option) => SERVICE_OPTIONS_LABELS[option]);

export const getFilledStars = (ratings: number) => {
  const decimalNumber = ratings % 1;
  if (decimalNumber < 0.25) {
    return ratings - decimalNumber;
  } else if (decimalNumber > 0.25 && decimalNumber < 0.75) {
    return ratings - decimalNumber + 0.5;
  } else {
    return ratings - decimalNumber + 1;
  }
};

export function delQuery(asPath: string) {
  return asPath.split('?')[0];
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024))?.toString());
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

/**
 * Returns the appropriate API base URL based on the current environment.
 * If running on the client-side, it determines the base URL based on the hostname.
 * Otherwise, it returns an empty string.
 *
 * @returns The API base URL or an empty string if not running on the client-side.
 */
export const getApiUrl = (): string | undefined => {
  // Initialize the API base URL variable
  let apiBaseUrl: string | undefined = '';

  // Check if the code is running on the client-side
  if (isClient()) {
    // Get the current hostname from the window object
    const host = window?.location?.hostname;

    // Determine the appropriate API base URL based on the hostname
    if (host && host.startsWith('us-')) {
      apiBaseUrl = US_BASE_API; // Set the base URL for the US environment
    } else {
      apiBaseUrl = BASE_API; // Set the base URL for other environments
    }
  }

  // Return the determined API base URL or an empty string
  return apiBaseUrl;
};
