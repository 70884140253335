const endpoints = {
  momos: {
    meta: {
      master_category: 'momos/third-party-links/master-categories',
    },
    microsite: {
      default: '/momos/microsite/brand',
      subscribe: '/momos/microsite/brand/:slug/subscribe',
      unsubscribe: '/momos/microsite/brand/:slug/unsubscribe',
      robotsDisallow: 'momos/microsite/robots-disallow',
    },
    survey: {
      default: 'momos/survey',
    },
    staff: {
      list: 'momos/staff/list',
    },
    vouchers: {
      default: 'momos/vouchers',
      instances: 'momos/vouchers/instances',
      multiple: 'momos/vouchers/multiple',
    },
    brands: {
      default: 'momos/brand',
    },
    inquiryForm: {
      default: 'momos/inquiry-form',
      getFormById: 'momos/inquiry-form/id',
    },
    review: {
      basicDetails: 'momos/reviews',
    },
    tnc: {
      bySlug: 'momos/tnc/slug',
    },
    optIn: {
      default: 'momos/opt-in',
    },
    shorturl: {
      tracking: 'momos/short-url/tracking',
    },
  },
  facebook: {
    graph: 'https://graph.facebook.com',
  },
  fraudValidator: {
    firehouse: 'momos/fraud-validator/firehouse',
    validateStoreId: 'momos/fraud-validator/validate-store-id',
  },
};

export default endpoints;
